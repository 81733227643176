@import 'src/app/styles';

.nav {
  display: grid;
  grid-template-columns: repeat(3, max-content);
  grid-template-rows: 1fr;
  gap: $default-indent;
  width: max-content;
  justify-self: center;
}

.navLink {
  color: $white-color;
  padding: $default-indent $big-indent;
  transition: color ease 0.3s;

  &.active {
    border-bottom: 2px solid $primary-color;
  }

  &:hover {
    color: rgba($white-color, 0.5);
    transition: color ease 0.3s;
  }
}
