@import 'src/app/styles/variables';

.button {
  border-radius: $default-border-radius;
  border: 1px solid $default-border-color;
  padding: $default-indent $big-indent;
  color: $white-color;
  transition: border ease 0.3s, color ease 0.3s;
  background-color: unset;
  cursor: pointer;
  text-align: center;

  &.full {
    width: 100%;
  }

  &:hover {
    border: 1px solid $white-color;
    transition: border ease 0.3s, color ease 0.3s;
  }

  &:disabled {
    border: 1px solid transparent;
    background-color: rgba($disabled-bg-color, 0.5);
    cursor: not-allowed;
  }
}
