@import 'src/app/styles';

.tab {
  position: relative;
  color: $white-color;
  height: max-content;
  padding: $default-indent $big-indent;
  cursor: pointer;
  transition: border ease 0.3s;
  border: 2px solid transparent;

  &:before {
    position: absolute;
    content: '';
    opacity: 0;
    width: 100%;
    height: 2px;
    background-color: $primary-color;
    color: $primary-text-color;
    bottom: 0;
    left: 0;
    transition: background-color ease 0.3s, opacity ease 0.3s;
  }

  &.active:before {
    opacity: 1;
  }

  &:hover:before {
    background-color: $light-primary-color;
    visibility: visible;
    opacity: 1;
    transition: background-color ease 0.3s, opacity ease 0.3s;
  }

  &:active:before {
    background-color: $contrast-primary-color;
    transition: background-color ease 0.3s;
  }

  &:focus {
    border: 2px solid $accent-color;
  }

  &:disabled {
    color: $disabled-text-color;
    cursor: not-allowed;

    &:before {
      opacity: 0;
      transition: opacity ease 0.3s;
    }
  }
}
