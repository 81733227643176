@import 'variables';

* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: $dark-gray-color;
  font-family: $primary-font-stack;
  font-weight: 400;
  font-size: 18px;
  color: $white-color;
}

h1 {
  font-family: $secondary-font-stack;
  font-weight: 600;
  font-size: 64px;
  color: $white-color;
}

h2 {
  font-family: $secondary-font-stack;
  font-weight: 600;
  font-size: 42px;
  color: $white-color;
}
