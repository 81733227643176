@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Meidum/Inter-Medium.woff2') format('woff2'),
    url('../fonts/Inter-Meidum/Inter-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter-Regular/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular/Inter-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/PlayfairDisplay-SemiBold/PlayfairDisplay-SemiBold.woff2')
      format('woff2'),
    url('../fonts/PlayfairDisplay-SemiBold/PlayfairDisplay-SemiBold.woff')
      format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Playfair Display';
  src: url('../fonts/PlayfairDisplay-Medium/PlayfairDisplay-Medium.woff2')
      format('woff2'),
    url('../fonts/PlayfairDisplay-Medium/PlayfairDisplay-Medium.woff')
      format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
