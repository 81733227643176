// Colors
$contrast-primary-color: #40418c;
$primary-color: #5557a4;
$light-primary-color: #7c7fbf;

$accent-color: #08d899;

$dark-gray-color: #252525;
$gray-color: #2f3140;
$light-gray-color: #f3f3f3;

$white-color: #fff;
$black: #000;

$success-color: #3ac922;
$light-success-color: #e6ffe2;
$error-color: #ff3030;
$light-error-color: #fff2f2;

$primary-text-color: $dark-gray-color;
$secondary-text-color: #676767;
$disabled-text-color: #949697;

$disabled-bg-color: #e7ebf1;

// Grid
$desktop-width: 1260px;
$tablet-width: 768px;
$mobile-width: 480px;

// Fonts
$primary-font-stack: 'Inter', sans-serif;
$secondary-font-stack: 'Playfair Display', serif;

$h1-font-size: 84px;
$h2-font-size: 56px;
$h3-font-size: 36px;
$h4-font-size: 24px;
$text-font-size: 18px;
$number-font-size: 200px;

// Options
$header-height: 80px;
$footer-height: 100px;

$default-border-radius: 5px;
$default-border-color: rgba($white-color, 0.5);

// Indents
$default-indent: 8px;
$small-indent: $default-indent / 2;
$big-indent: $default-indent * 2;
$large-indent: $default-indent * 3;

$light-bg-color: #f0f5f5;
