@import 'src/app/styles';

.loader {
  display: grid;
  width: 100%;
  height: 100%;
}

.body {
  display: grid;
  grid-template-columns: 1fr;
  gap: $default-indent;
  justify-items: center;
  align-items: center;
  width: max-content;
  height: max-content;
  justify-self: center;
  align-self: center;
}

.spin {
  width: 40px;
  height: 40px;
  fill: $primary-color;
  animation: animate-spin infinite linear 1s;
}

@keyframes animate-spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
